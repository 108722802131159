import React, { FC, useContext, useState } from 'react';
import { PhoneNumber } from '../../classes/PhoneNumber';
import WebChat from '../webchat/webchat';
import Campaign from '../Campaign/Campaign';
import './HeaderTab.css';
import ConfigContext from '../ConfigContext/Config';

interface HeaderTabProps {
  formData:any; 
  botStyles: any;
  message: any;
  onShowModal: any;
  onShowTimeoutModal: any;
}

const HeaderTab: FC<HeaderTabProps> = ({ formData, botStyles, message, onShowModal, onShowTimeoutModal }) => {
  const [activeTab, setActiveTab] = useState('webchat');
  const { features } = useContext(ConfigContext);

  const isUserWhitelisted = (contact: any, type: any): boolean => {
    const whitelistEmail = ['adnaan.fredericks@xibsolutions.com'];
    const whitelistPhone = [new PhoneNumber('0829940527').toString()];
    if (type === 'phone') {
      return whitelistPhone.includes(contact)
    } else if (type === 'email') {
      return whitelistEmail.includes(contact)
    }
    return false;
  }

  return (
    <div style={{width: '100%'}}>
      {features.webchat && features.campaigns ? (
        <div className="tabs" style={{position: 'sticky', top: 0, zIndex: 1, background: '#fff'}}>
          <div
            className={`tab ${activeTab === 'webchat' ? 'active' : ''}`}
            onClick={() => setActiveTab('webchat')}
          >
            WebChat
          </div>
          {isUserWhitelisted(formData.contact, formData.type) && (
            <div
              className={`tab ${activeTab === 'campaign' ? 'active' : ''}`}
              onClick={() => setActiveTab('campaign')}
            >
              Campaign
            </div>
          )}
        </div>
      ) : null}
      {features.webchat && <div style={{ display: activeTab === 'webchat' || !features.campaigns ? 'block' : 'none' }}>
        <WebChat styleOptions={botStyles} formData={formData} onShowModal={onShowModal} onShowTimeoutModal={onShowTimeoutModal} startMessage={message}/>
      </div>}
      {features.campaigns && <div style={{ display: activeTab === 'campaign' || !features.webchat ? 'block' : 'none' }}>
          <Campaign/>
        </div>}
    </div>
  );
};

export default HeaderTab;
