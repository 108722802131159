// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	background: var(--login-background);
	animation: gradient 15s ease infinite;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

code {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
	Helvetica, Arial, "Lucida Grande", sans-serif;
}



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,mCAAmC;CACnC,qCAAqC;EACpC,SAAS;EACT;+CAC6C;EAC7C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;IACI,SAAS;IACT,UAAU;IACV,YAAY;EACd;;AAEF;CACC;EACC,2BAA2B;CAC5B;CACA;EACC,6BAA6B;CAC9B;CACA;EACC,2BAA2B;CAC5B;AACD;;AAEA;CACC;8CAC6C;AAC9C","sourcesContent":["body {\r\n\tbackground: var(--login-background);\r\n\tanimation: gradient 15s ease infinite;\r\n  margin: 0;\r\n  font-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", \r\n  Helvetica, Arial, \"Lucida Grande\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\nbody, html, #root {\r\n    margin: 0;\r\n    padding: 0;\r\n    height: 100%;\r\n  }\r\n\r\n@keyframes gradient {\r\n\t0% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n\t50% {\r\n\t\tbackground-position: 100% 50%;\r\n\t}\r\n\t100% {\r\n\t\tbackground-position: 0% 50%;\r\n\t}\r\n}\r\n\r\ncode {\r\n\tfont-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", \r\n\tHelvetica, Arial, \"Lucida Grande\", sans-serif;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
