// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 4vh;
  }
  
  .tab {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 0.02857em;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  }
  
  .tab:hover {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .tab.active {
    color: #3f51b5;
    border-bottom-color: #3f51b5;
  }`, "",{"version":3,"sources":["webpack://./src/components/HeaderTab/HeaderTab.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4CAA4C;IAC5C,WAAW;IACX,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,eAAe;IACf,0BAA0B;IAC1B,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,oCAAoC;IACpC,wEAAwE;EAC1E;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,cAAc;IACd,4BAA4B;EAC9B","sourcesContent":[".tabs {\n    display: flex;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n    width: 100%;\n    height: 4vh;\n  }\n  \n  .tab {\n    flex-grow: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 16px;\n    cursor: pointer;\n    color: rgba(0, 0, 0, 0.87);\n    text-transform: uppercase;\n    font-size: 0.875rem;\n    letter-spacing: 0.02857em;\n    font-weight: 500;\n    border-bottom: 2px solid transparent;\n    transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;\n  }\n  \n  .tab:hover {\n    color: rgba(0, 0, 0, 0.87);\n  }\n  \n  .tab.active {\n    color: #3f51b5;\n    border-bottom-color: #3f51b5;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
