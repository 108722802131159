// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
    text-align: center;
    background-color: #f8f9fa;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6c757d;
  }
  .error-content {
    width: 550px;
  }
  
  .error-page h1 {
    margin-top: 20px;
    font-size: 1.5em;
  }
  
  .error-page svg {
    color: #dc3545;
  }

  .collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
  .content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
    text-align: left;
    white-space: pre-line;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/ConfigContext/Config.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;EAChB;EACA;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,oCAAoC;IACpC,yBAAyB;IACzB,gBAAgB;IAChB,qBAAqB;EACvB","sourcesContent":[".error-page {\n    text-align: center;\n    background-color: #f8f9fa;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    color: #6c757d;\n  }\n  .error-content {\n    width: 550px;\n  }\n  \n  .error-page h1 {\n    margin-top: 20px;\n    font-size: 1.5em;\n  }\n  \n  .error-page svg {\n    color: #dc3545;\n  }\n\n  .collapsible {\n    background-color: #777;\n    color: white;\n    cursor: pointer;\n    padding: 10px;\n    width: 100%;\n    border: none;\n    text-align: left;\n    outline: none;\n    font-size: 15px;\n  }\n  \n  .content {\n    padding: 0 18px;\n    max-height: 0;\n    overflow: hidden;\n    transition: max-height 0.2s ease-out;\n    background-color: #f1f1f1;\n    text-align: left;\n    white-space: pre-line;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
