// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
    .App {
      flex-direction: column;
      overflow: hidden;
    }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;IACI;MACE,sBAAsB;MACtB,gBAAgB;IAClB;AACJ;;AAEA;;;EAGE,sBAAsB;AACxB","sourcesContent":[".App {\r\n  text-align: center;\r\n  display: flex;\r\n  flex-direction: row;\r\n  min-height: 100vh;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    .App {\r\n      flex-direction: column;\r\n      overflow: hidden;\r\n    }\r\n}\r\n\r\n*,\r\n*::before,\r\n*::after {\r\n  box-sizing: border-box;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
