import React, { FC, useState, useContext, useEffect, useRef } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Button,
  TablePagination,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardContent,
  Typography,
  SelectChangeEvent,
  Chip
} from '@mui/material';
import ExposureIcon from '@mui/icons-material/Exposure';
import { styled } from '@mui/system';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';
import ConfigContext from '../ConfigContext/Config';
import jwtDecode from 'jwt-decode';

const backgroundImageModule = require('../../assets/tigerbrands/whatsapp-bg.png');
const backgroundImage = backgroundImageModule.default;

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Divider from '@mui/material/Divider';
import { UserContext } from '../Auth/Auth';


interface CampaignProps { }

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

const StyledParentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
});

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '10px',
  height: '96vh',
  maxWidth: '800px'
});

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledCard = styled(Card)({
  minWidth: 275,
  marginBottom: '10px',
  padding: '20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledRoot = styled('div')(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column-reverse',
  padding: theme.spacing(2),
  borderRadius: '15px',
  width: '400px',
}));

const StyledMessageContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#DCF8C6',
  padding: '10px',
  borderRadius: '7.5px',
  width: 'fit-content',
  maxWidth: '85%',
  marginBottom: theme.spacing(1),
  alignSelf: 'flex-end',
}));

const StyledTime = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const Campaign: FC<CampaignProps> = () => {
  const { properties } = useContext(ConfigContext);
  const [channel, setChannel] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [params, setParams] = useState<string[]>([]);
  const [fields, setFields] = useState<JSX.Element[]>([]);
  const [contacts, setContacts] = useState([]);
  const [totalContacts, setTotalContacts] = useState();
  const { getSession } = useContext(UserContext);
  const [campaignMessage, setCampaignMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [originalMessage, setOriginalMessage] = useState('');
  const url = `${properties.baseUrls.campaign}/api/${properties.id}/v1/xs/campaign/schedule/submit`;
  const [campaignStatus, setCampaignStatus] = useState<string>('');

  const handleParamChange = (index: number, value: string) => {
    const newParams = [...params];
    newParams[index] = value;
    setParams(newParams);
    setCampaignStatus('');
  };

  const generateFields = (message: string) => {
    // Find all instances of ${} in the message
    const matches = message.match(/\$\{\d+\}/g) || [];

    if (matches.length === 0) {
      return [
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p" align='center'>
            No parameters needed
          </Typography>
        </Grid>
      ];
    }

    return matches.map((match, index) => {
      return (
        <Grid item xs={12} sm={6} key={index}>
          <TextField
            id={`param-${index + 1}`}
            label={`Parameter ${index + 1}`}
            variant="outlined"
            value={params[index] || ''}
            onChange={(event) => handleParamChange(index, event.target.value)}
            fullWidth
          />
        </Grid>
      );
    });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<unknown>) => {
    setRowsPerPage(parseInt((event.target as HTMLInputElement).value, 10));
    setPage(0);
  };


  // useEffect(() => {
  //   const fetchContacts = async () => {
  //     try {
  //       const response = await axios.get('https://hapi.xiva-react-chatbot-af.stage.xibsolutions.com/dynamo/contacts', {
  //         params: {
  //           pageSize: rowsPerPage,
  //           startKey: page * rowsPerPage,
  //           pk: properties.id
  //         },
  //       });
  //       setContacts(response.data.items);
  //       setTotalContacts(response.data.count);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchContacts();
  // }, [page, rowsPerPage]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`${properties.baseUrls.campaign}/dynamo/templates?pk=${properties.id}`);
        setTemplates(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    let renderedMessage = originalMessage;
    params.forEach((param, index) => {
      const placeholder = `\\$\\{${index + 1}\\}`;
      const regex = new RegExp(placeholder, 'g');
      renderedMessage = renderedMessage.replace(regex, param || '');
    });
    setCampaignMessage(renderedMessage);
    setFields(generateFields(originalMessage));
  }, [campaignType, params]);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignStatus('');
    console.log("upload started")
    const file = event.target.files![0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target!.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Extract column names from the first row
      const columnNames = jsonData.shift() as string[];

      const newContacts = jsonData.map((row: any[]) => {
        let contact: any = {
          pk: properties.id,
          firstName: row[0],
          surname: row[1],
          email: row[2],
          msisdn: row[3]
        };

        // Add additional fields
        for (let i = 4; i < columnNames.length; i++) {
          contact[columnNames[i]] = row[i];
        }

        return contact;
      });

      setContacts(newContacts);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCampaignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignStatus('');
    setCampaignName(event.target.value as string);
  };

  const handleCampaignTypeChange = (event: SelectChangeEvent<string>) => {
    setCampaignStatus('');
    const selectedTemplate = templates.find(template => template.SK === 'TEMPLATE#' + event.target.value);
    if (selectedTemplate) {
      setOriginalMessage(selectedTemplate.message);
    }
    setCampaignType(event.target.value as string);
  };

  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    setCampaignStatus('');
    setChannel(event.target.value as string);
  };

  const generateExcel = () => {
    const data = [
      ['FullName', 'Surname', 'Email', 'MSISDN'],
      // Add your data here. Each array is a row in the Excel file.
      // For example:
      ['John', 'Doe', 'john@example.com', '1234567890'],
      ['Jane', 'Doe', 'jane@example.com', '0987654321'],
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(dataBlob, 'template.xlsx');
  };

  const runCampaign = async () => {
    setCampaignStatus('Processing Contacts...');
    const currentUser: any = (await getSession());
    const token = currentUser.idToken.jwtToken;
    const decodedToken: any = jwtDecode(token);
    console.log(`${properties.id}${decodedToken.phone_number}`);
    const people = contacts.map(row => ({
      person: {
        personId: `${properties.id}-${row.msisdn}`,
        name: row.firstName,
        surname: row.surname,
        mobile: row.msisdn.toString()
      },
      clientState: {
        type: campaignType,
        Person: row.firstName + " " + row.surname,
        days: campaignMessage
      },
      campaignType: 'chatbot',
      campaignAlias: `bcx-${properties.id}:dynamic`,
      campaignVersionAlias: "v1",
      campaignChannel: channel
    }));

    const payload = {
      originId: `${properties.id}${decodedToken.phone_number}`,
      campaigns: [...people]
    }

    try {
      setCampaignStatus('Scheduling Campaign...');
      const response = await axios.post(url, payload);
      console.log(response.data);
      setCampaignStatus('Success! Your campaign has successfully kicked off.');
      setCampaignName('');
      setCampaignType('');
      setChannel('');
      setContacts([]);
      setParams([]);
    } catch (error) {
      console.error(error);
      setCampaignStatus('Failed. Please try again later.');
    }
  };

  return (
    <StyledContainer>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <Typography variant="h5" component="h2" align='left'>
                    Contact Management
                  </Typography>
                  <Typography variant="body1" component="p" align='left'>
                    Download the template, fill it out with your contacts, then upload it here.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={generateExcel}
                  >
                    Download Template
                  </StyledButton>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    component="label"
                    style={{ margin: '8px' }}
                  >
                    Upload Template
                    <input
                      style={{ display: 'none' }}
                      id="upload-button"
                      name="upload-button"
                      type="file"
                      onChange={handleUpload}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <StyledTable aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Surname</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>MSISDN</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contacts.length > 0 ? (
                          contacts.map((contact, index) => (
                            <TableRow key={index}>
                              <TableCell>{contact.firstName}</TableCell>
                              <TableCell>{contact.surname}</TableCell>
                              <TableCell>{contact.email}</TableCell>
                              <TableCell>{contact.msisdn}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No contacts have been uploaded
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </StyledTable>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={contacts.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" component="h2" align='left'>
                Campaign Config
              </Typography>
              <Typography variant="body1" component="p" align='left'>
                Fill out the form below to configure your campaign. Select the campaign type, enter a name for your campaign, and select the channel through which the campaign will be conducted.
              </Typography>
              <Grid container spacing={3} style={{ marginTop: '5px' }}>
                <Grid item xs={12} sm={6}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <TextField
                      id="campaign-name"
                      label="Campaign Name"
                      value={campaignName}
                      onChange={handleCampaignNameChange}
                      fullWidth
                    />
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <InputLabel id="campaign-type-label">Campaign Type</InputLabel>
                    <Select
                      labelId="campaign-type-label"
                      id="campaign-type"
                      value={campaignType}
                      onChange={handleCampaignTypeChange}
                      label="Campaign Type"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {templates.map((template, index) => (
                        <MenuItem value={template.SK.replace('TEMPLATE#', '')} key={index}>
                          {template.SK.replace('TEMPLATE#', '')}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledFormControl variant="outlined" fullWidth>
                    <InputLabel id="channel-label">Channel</InputLabel>
                    <Select
                      labelId="channel-label"
                      id="channel"
                      value={channel}
                      onChange={handleChannelChange}
                      label="Channel"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'teams'}>Microsoft Teams</MenuItem>
                      <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Grid>
              </Grid>
            </CardContent>

            <Divider variant="middle" style={{ margin: '10px 0' }} />
            {campaignType && channel ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CardContent>
                    <Typography variant="h5" component="h2" align='left'>
                      Set Parameters
                    </Typography>
                    <Typography variant="body1" component="p" align='left'>

                    </Typography>
                    <Grid container spacing={3} style={{ marginTop: '5px' }}>
                      {campaignType && fields}
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h5" component="h2" align='left' style={{ marginBottom: '10px' }}>
                      Preview
                    </Typography>
                    <StyledParentContainer>
                      <StyledRoot>
                        <StyledMessageContainer>
                          <Typography variant="body1" component="p" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', textAlign: 'left' }}>
                            {campaignMessage}
                          </Typography>
                          <StyledTime>
                            <Typography variant="caption" component="p" style={{ color: 'grey', marginRight: '5px' }}>
                              16:20
                            </Typography>
                            <DoneAllOutlinedIcon style={{ color: '#29AFDF', fontSize: '18px' }} />
                          </StyledTime>
                        </StyledMessageContainer>
                      </StyledRoot>
                    </StyledParentContainer>
                  </CardContent>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h5" component="h2" align='center' style={{ marginTop: '20px' }}>
                No preview available
              </Typography>
            )}
          </StyledCard>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <StyledCard>
            <CardContent>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={contacts.length === 0 || !campaignName || !campaignType || !channel}
                  onClick={runCampaign}
                >
                  Run Campaign
                </Button>
                {campaignStatus && (
                <Chip
                  label={campaignStatus}
                  style={{
                    marginLeft: '30px',
                    backgroundColor: campaignStatus === 'Success! Your campaign has successfully kicked off.' ? 'green' : campaignStatus === 'Failed. Please try again later.' ? 'red' : 'orange',
                    color: 'white'
                  }}
                />
                )}
              </div>
            </CardContent>
          </StyledCard>
        </Grid>

      </Grid>
    </StyledContainer>



  );
};

export default Campaign;
