import React, {useState} from 'react';
import './form.css';
import {PhoneNumber} from '../../classes/PhoneNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

type Props = {
	formSubmit: Function;
	onError: string;
	onErrorClose: any;
  };


function Form(props: Props) {
	const [name, setName] = useState<string>('');
	const [hasNameError, setHasNameError] = useState<boolean>(false);
	const [loginType, setLoginType] = useState('unknown');

	const [contact, setContact] = useState<string>('');
	const [hasContactError, setHasContactError] = useState<boolean>(false);
	const [hasFormError, setHasFormError] = useState<boolean>(false);

	//validation for basic input
	const validateInput = (value: string) => {
		if (value.trim() === ''){
			return false;
		  }
		  return true;
	}

	const validateEmail = (email: string) => {
		return  (
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
		);
	  };

	  const validateMsisdn = (msisdn: string) => {
		return  (
			/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(msisdn)
		);
	  };

	const validateContact = (input: string) => {
			const emailCheck =  validateEmail(input.trim());
			const cellCheck =  validateMsisdn(input.trim());
			if(emailCheck) {
				setLoginType('email');
				return true;
			}
			if(cellCheck) {
				setLoginType('phone');
				return true
			}
			return false;
	}

	//validate form
	const validateForm = () => {
				//validate that no errors on inputs
				if(hasContactError || hasNameError) {
					return false;
				}				
				// validate that inputs have values
				if(!(name.length > 0) || !(contact.length > 0)) {
					return false;
				}
				return true
	}

	//dynamic function to update state for forms items by name
	const handleFormUpdate = (event: any) => {
		switch(event.target.name) {
			case 'full_name':
				setName(event.target.value);
				const nameValid = validateInput(event.target.value);
				if (!nameValid) {
					setHasNameError(true);
				}else {
					setHasNameError(false);
				}
			break;
			case 'contact':
				setContact(event.target.value);
				const contactValid = validateContact(event.target.value);
				if (!contactValid) {
					setHasContactError(true);
				}else {
					setHasContactError(false);
				}
			break;
			default:
			   console.warn('Form Name not recognized');
		}

		if(!hasContactError && !hasNameError) {
			setHasFormError(false);
		}
	}

	const handleFormSubmit = (event: any) => {
		event.preventDefault();
		
		const formValid = validateForm();
		if(!formValid) {
			setHasFormError(true);
			return;
		}
		setHasFormError(false);
		props.formSubmit({name: name, contact: loginType === "email" ? contact : new PhoneNumber(contact).toString(), type: loginType});
	}

  return (
    <React.Fragment>
	<div className="screen">
		<div className="screen__content">
			<form className="login" onSubmit={handleFormSubmit}>
			{/* <div className="login__field_btn_select">
					<button type='button' className="role-btn" role="button" onClick={handleLoginSwitch.bind(null, 'email')}>Email</button>
					<button type='button' className="role-btn" role="button" onClick={handleLoginSwitch.bind(null, 'msisdn')}>Cell Phone</button>
				</div> */}
                <div className="login__field">
					<i className="login__icon fas fa-user"></i>
					<input type="text" name="full_name" className="login__input" onChange={handleFormUpdate} value={name} placeholder="Full Name" />
					{hasNameError && <p className="login__error">Please enter a valid name.</p>}
				</div>
				<div className="login__field">
					<i className="login__icon fas fa-user"></i>
					<input type='text' name="contact" className="login__input" placeholder='Please enter a valid Email/Cellphone' onChange={handleFormUpdate} value={contact} />
					{hasContactError && <p className="login__error">Please validate your Email or Contact</p>}
				</div>
				
				<button className="button login__submit">
					<span className="button__text">Login</span>
					<i className="button__icon fas fa-chevron-right"></i>
				</button>
				{hasFormError && <p className="login__error">Sorry, Please make sure the form is filled out correctly.</p>}	
				{props.onError && (
  <div className="error__wrapper">
	<div className="error-wrapper_left">
    <i><FontAwesomeIcon icon={faCircleExclamation} /></i>
    <p>{props.onError}</p>
	</div>
	<svg onClick={props.onErrorClose}
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="12"
		viewBox="0 0 24 24"
		fill="none"
		stroke="#ff5a5f"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		>
		<line x1="18" y1="6" x2="6" y2="18" />
		<line x1="6" y1="6" x2="18" y2="18" />
		</svg>
  </div>
)}
			</form>
		</div>
		<div className="screen__background">
			<span className="screen__background__shape screen__background__shape4"></span>
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
	</React.Fragment>
  );
}

export default Form;
