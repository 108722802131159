import _ from 'lodash';
import axios from 'axios';

const decryptToken = async (encryptedToken: string): Promise<string> => {
    try {
        const env = process.env.NODE_ENV === 'production' ? 'prod': 'stage';
        const response = await axios.post(`https://hapi.xiva-react-chatbot-af.${env}.xibsolutions.com/dynamo/decrypt`, { encryptedToken: encryptedToken })
        return response.data;
    } catch (error) {
        throw error;
    }
}

const extractPayloadAndLogin = async (encryptedToken: string, onHandleFormSubmit: any, dispatch) => {
    try {
        if (encryptedToken) {
            const payload: any = await decryptToken(encryptedToken);
            console.log(payload)
            login(payload, dispatch, onHandleFormSubmit);
        }
    } catch (error) {
        console.log(error)
    }
}

const login = (payload, onHandleFormSubmit, dispatch) => {
    console.log("attempting to login")
    if (payload.name && payload.contact && payload.type) {
        console.log(payload)
        if (payload.message) {
            dispatch({type: 'SET_MESSAGE', payload: payload.message});
        }
        const form = payload;
        onHandleFormSubmit(form);
    }
}

export const toggleUrlParamsFeature = (urlParams, features, onHandleFormSubmit, dispatch) => {
    console.log(urlParams, features.urlParamAuth)
    if ((!_.isEmpty(urlParams.name) && !_.isEmpty(urlParams.contact) && !_.isEmpty(urlParams.type)) && features.urlParamAuth) {
        login(urlParams, onHandleFormSubmit, dispatch);
    }
}

export const toggleUrlTokenAuthFeature = async (urlParams, features, onHandleFormSubmit, dispatch) => {
    if (!_.isEmpty(urlParams.token) && features.urlTokenAuth) {
        console.log("urlTokenAuth is enabled")
        const encryptedToken = decodeURIComponent(urlParams.token);
        await extractPayloadAndLogin(encryptedToken, onHandleFormSubmit, dispatch);
    }
}

export const toggleCognitoFeature = async (cognito, isEmpty, setOTP, dispatch, setSubmit, setLoading, getSession) => {
    try {
    if (!cognito) {
        console.log("cognito is disabled")
        if (!isEmpty(localStorage.getItem('chat-session'))) {
            console.log("chat already exists")
            setOTP(true);
            dispatch({ type:'SET_FORM_DATA', payload: JSON.parse(localStorage.getItem('chat-session'))});
            setSubmit(false);
            setLoading(false);
        } else {
            setLoading(false);
        }
    } else if (cognito) {
        console.log("cognito is enabled")
        
        const isLoggedIn = await getSession()
        console.log("loggedIN: "+isLoggedIn)
        if (!isEmpty(localStorage.getItem('chat-session') && isLoggedIn)) {
            console.log("chat already exists")
            setOTP(true);
            dispatch({ type:'SET_FORM_DATA', payload: JSON.parse(localStorage.getItem('chat-session'))});
            setSubmit(false);
            setLoading(false);
        } else {
        setLoading(false);
        }
    }
} catch (error) {
    console.log(error);
    throw error;
}
}

export const toggleOTPFeature = (features, getSession, setOTP, setSubmit, dispatch, setLoading) => {
    if (features.OTP) {
        console.log("OTP is enabled")
        getSession().then((response: any) => {
            console.info('getSession', response);
            if (response) {
                setOTP(true);
                setSubmit(false);
                dispatch({ type:'SET_FORM_DATA', payload: localStorage.getItem('chat-session') ? JSON.parse(localStorage.getItem('chat-session')) : {}});
                setLoading(false);
            }
        }).catch((error) => {
            console.info('getSession', error);
            localStorage.removeItem('chat-session');
            setLoading(false);
        })
    }
}